/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout/profile'

const competenceRouter = {
  path: '/competence',
  component: Layout,
  redirect: '/competence/my-chart',
  name: 'Competence',
  meta: {
    title: 'Competence',
    icon: 'chart'
  },
  children: [
    {
      path: 'my-chart',
      component: () => import('@/views/Competence/highcharts-my'),
      name: 'HighchartsMy',
      meta: { title: 'Изменить шаблон', noCache: true, admin: true}
    },
    // {
    //   path: 'my-chart-user/:id',
    //   component: () => import('@/views/Competence/highcharts-my-user'),
    //   name: 'HighchartsMyUser',
    //   meta: { title: 'Компетенции Сотрудника', noCache: true },
    //   hidden: true
    // },
    // {
    //   path: 'department',
    //   component: () => import('@/views/Competence/department'),
    //   name: 'HighchartsMyDepertment',
    //   meta: { title: 'Изменить отдел', noCache: true }
    // }
  ]
}

export default competenceRouter
