// import Layout from "@/layout/profile";
import EmptyLayout from "@/layout/EmptyLayer";

// import ProjectChange from "@/views/Profile/ProjectChange";
import HiddenProjectsList from "@/views/HiddenProjectsList";

const projectRouter = {
  path: "/project",
  name: "Project",
  redirect: "/project/projectsList",
  component: EmptyLayout,
  children: [
    // {
    //     path: "welcome",
    //     name: "welcome",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "@/views/WelcomePage.vue"),
    // },
    // {
    //     path: "change",
    //     name: "change",
    //     component: ProjectChange
    // },
    {
      path: "projectsList",
      component: HiddenProjectsList,
      name: "HiddenProjectsList",
    },
  ],
};

export default projectRouter;
