// import Axios from 'axios'

const getDefaultState = () => {
    return {
      user: null
    };
  };

const state = getDefaultState()

const getters = {
    isLoggedIn: state => {
        return state.user;
    },
}

const mutations = {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_PARAM_USER: (state, { param, value }) => {
      state.user[param] = value;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
}

const actions = {
    login: ({ commit }, { token, user }) => {
      // commit('SET_USER', user);
      localStorage.setItem('token', token);
      // set auth header
      // Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
      localStorage.removeItem('token');
    },
    changeUser: ({ commit }, { data }) => {
      for (let key in data) {
        commit('SET_PARAM_USER', { param: key, value: data[key]});
        // тело цикла выполняется для каждого свойства объекта
      }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}