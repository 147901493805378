import EmptyLayout from "@/layout/EmptyLayer";

const profileRouter = {
  path: "/profile",
  name: "Profile",
  redirect: "/profile/my",
  component: EmptyLayout,
  children: [
    // {
    //     path: "my",
    //     name: "ProfileHome",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "@/views/Profile/Home.vue"),
    //     meta: {
    //         user: true
    //     }
    // },
    {
      path: "edit",
      name: "ProfileEdit",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Profile/Edit.vue"),
      meta: {
        user: true,
      },
    },
  ],
};

export default profileRouter;
