/** When your routing table is too long, you can split it into small modules**/
import Layout from "@/layout/landing";

const competenceRouter = {
  path: "/auth",
  component: Layout,
  redirect: "/auth/signup-login",
  name: "Auth",
  children: [
    {
      path: "signup-login",
      name: "SignUp_LogIn",
      component: () => import("@/views/Auth/SignUp_LogIn.vue"),
    },
    {
      path: "forgot",
      name: "Forgot",
      component: () => import("@/views/Auth/Forgot.vue"),
    },
    {
      path: "reset/:token",
      name: "Reset",
      component: () => import("@/views/Auth/Reset.vue"),
    },
    {
      path: `reset/mailsent`,
      name: "MailSent",
      component: () => import("@/views/Auth/RecoveryTemplate.vue"),
    },
    {
      path: `password-changed`,
      name: "PasswordChanged",
      component: () => import("@/views/Auth/RecoveryTemplate.vue"),
    },
    {
      path: `link-expired`,
      name: "LinkExpired",
      component: () => import("@/views/Auth/RecoveryTemplate.vue"),
    },
    {
      path: `account-activated`,
      name: "AccountActivated",
      component: () => import("@/views/Auth/RecoveryTemplate.vue"),
    },
  ],
};

export default competenceRouter;
