import { createRouter, createWebHistory } from "vue-router";
import { logout } from "@/api/auth.js";

import NewLanding from "@/views/Landing";
import NewLandingEnterprise from "@/views/LandingForOrganizations";
// import NewLandingGOS from "@/views/LandingForGOS";

import AboutPage from "@/views/About";
import VKRPage from "@/views/VKRPage";

import betaRouter from "./modules/beta";
import profileRouter from "./modules/profile";
import authRouter from "./modules/auth";
import competenceRouter from "./modules/competence";
import eduRouter from "./modules/edu";
import projectRouter from "./modules/project";
// import organizationRouter from "./modules/organization";
import adminRouter from "./modules/admin";

import store from "../store/index.js";

const routes = [
  {
    path: "/",
    component: NewLanding,
  },
  {
    path: "/business",
    component: NewLandingEnterprise,
  },
  {
    path: "/about",
    component: AboutPage,
  },
  {
    path: "/vkr",
    component: VKRPage,
  },
  authRouter,
  profileRouter,
  competenceRouter,
  eduRouter,
  projectRouter,
  adminRouter,
  // organizationRouter,
  betaRouter,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.user)) {
    if (
      store.state.auth.user?.role === undefined ||
      store.state.auth.user?.role === null
    ) {
      // logout();
      localStorage.removeItem("vuex");
      next("/");
    }
    if (store.state.auth.user) {
      /* Проверка на админа и редирект на чек таск */
      if (store.state.auth.user.role.name == "admin") {
        next("/admin/checkTask");
      }
      /* END */
      if (
        store.state.auth.user.hasOwnProperty("parent_company") &&
        store.state.auth.user.parent_company.length > 0
      ) {
        next("/beta/o/profile");
      }
      if (
        store.state.auth.user.hasOwnProperty("UserPinTask") &&
        store.state.auth.user.UserPinTask.length > 0
      ) {
        next();
        return;
      }
      next("/beta/start");
      return;
    }
    next("/");
  } else if (to.matched.some((record) => record.meta.admin)) {
    if (store.state.auth.user.role === undefined) {
      logout();
      localStorage.removeItem("vuex");
      next("/");
    }
    if (store.state.auth.user) {
      let hasRole = false;
      // апельсин не понятно пока что как проверить роль относительно отдела, пока что подразумевается 1 отдел
      if (store.state.auth.user.role.name === "admin") {
        hasRole = true;
      }
      if (!hasRole) {
        next("/");
        return;
      }
      next();
      return;
    }
    next("/");
  }
  next();
});

export default router;
