import Layout from "@/layout/profile";
import EmptyLayout from "@/layout/EmptyLayer";

const eduRouter = {
  path: "/edu",
  name: "Edu",
  redirect: "/edu/about",
  component: EmptyLayout,
  children: [
    {
      path: "level/:level",
      name: "LevelPage",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/LevelPage.vue"),
      meta: {
        user: true,
      },
    },
    {
      path: "about/:section",
      name: "ProfileAbout",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Profile/About.vue"),
      meta: {
        user: true,
      },
    },
    {
      path: "about",
      name: "Edu",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/Edu/EduPage.vue"),
      meta: {
        user: true,
      },
    },
  ],
};

export default eduRouter;
