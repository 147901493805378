import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueJivosite from "@bchteam/vue-jivosite";
import VueYandexMetrika from "vue3-yandex-metrika";

import "../src/assets/css/mdb.min.css";
import "../src/assets/js/mdb.min.js";

import "boxicons/css/boxicons.min.css";

import ElementPlus from "element-plus";
// import 'element-plus/lib/theme-chalk/index.css';
import "element-plus/dist/index.css";

import "gsap";

import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";

if (process.env.VUE_APP_PROD==="true") {
  createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    //   .use(VueJivosite, { widgetId: 'YhcMYaoExf' })
    .use(VueYandexMetrika, {
      id: 87730981,
      router: router,
      env: "production"
      // other options
    })
    .mount("#app");
} else {
  createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    // .use(VueJivosite, { widgetId: "YhcMYaoExf" })
    .mount("#app");
}


